import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ModalPermanentlyRemoveUser = ({ isOpen, setIsOpen, handleConfirm }) => {
  const [isLoading, setIsLoading] = useState();
  const [isDeleted, setIsDeleted] = useState();

  const closeModal = () => setIsOpen(false);
  const navigate = useNavigate();

  const handleButtonConfirm = async () => {
    setIsLoading(true);
    const { data: output } = await handleConfirm();

    if (output.success) {
      setIsDeleted(true);
    }
    setIsLoading(false);
    // closeModal();
  };

  const handleLogout = () => {
    closeModal();
    navigate("/");
    localStorage.clear();
  };

  return (
    <div>
      {isOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          role="dialog"
          aria-modal="true"
        >
          <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6">
            <h2 className="text-lg font-semibold text-gray-800 text-center">
              {isDeleted
                ? "Your account has been deleted!"
                : "Are you sure you want to permanently remove your account?"}
            </h2>
            {isDeleted ? (
              <div className="flex flex-1 justify-center">
                <button
                  onClick={handleLogout}
                  className="mt-2 px-4 py-2 bg-[#E4A61A] text-white rounded-lg hover:[#e3b039] flex items-center justify-center w-[75px]"
                >
                  Logout
                </button>
              </div>
            ) : (
              <>
                <p className="text-sm text-gray-600 mt-2">
                  This action cannot be undone. All your data will be deleted
                  permanently.
                </p>
                <div className="mt-6 flex justify-end space-x-4">
                  <button
                    onClick={closeModal}
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300"
                  >
                    No
                  </button>
                  <button
                    onClick={handleButtonConfirm}
                    className="px-4 py-2 bg-[#E4A61A] text-white rounded-lg hover:[#e3b039] flex items-center justify-center w-[75px]"
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Yes"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalPermanentlyRemoveUser;
