import React from "react";
import Side from "../../components/authentication-side";
import VerifyCodeAuth from "../../views/verifycodeauth";
const Register = () => {
  return (
    <React.Fragment>
      <div className="grid grid-cols-12 text-black">
        <div className="lg:col-span-7 md:col-span-6 md:block hidden">
          <Side />
        </div>
        <div className="lg:col-span-5 md:col-span-6 col-span-12">
          <VerifyCodeAuth />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
