/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/register";
import Login from "./pages/login";
import Training from "./pages/training-admin";
import SuperAdmin from "./pages/super-admin";
import TrainingPortal from "./pages/training-portal";

import ExamTrainingPortal from "./pages/exam-training-portal";
import {
  ProtectedRouteMiddleware,
  ProtectedRouteMiddlewareSuperAdminHalcyonEco,
} from "./components/auth-gaurd";
import VerifyCode from "./pages/verifycode";
import Settings from "./pages/settings";
import ResetPassword from "./pages/resetpassword";
import VerifyActiveCodeAuth from "./views/verifycodeauth";
import Savings from "./pages/savings";
import Devices from "./pages/devices";
import Dashboard from "./pages/dashboard";
import { useUserData } from "./services/user/queries";
import TableEmployeesSummary from "./components/table-employees-summary";
import TableOrganisations from "./components/table-organisations";
import TableLocation from "./components/table-location";
import TableDeviceOneLocation from "./components/table-devices-one-location";
import TableOrganizationSuperAdmin from "./components/table-organizations-superAdmin";
import ModalWindow from "./components/all-modal-window/modal-window";
import TableLocations from "./components/table-locations-superAdmin";
import { useUserStore } from "./storesZustand/useUserStore";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsOfService from "./pages/terms-of-service";
import { Amplify } from "aws-amplify";
import api from "./utils/api";

function App() {
  const { data: userData, error: errorUserData } = useUserData();

  const [user] = useUserStore((state) => [state.user]);

  useEffect(() => {
    const fcmToken = window?.FCM_TOKEN;
    const token = localStorage.getItem("token");

    if (token && user && fcmToken && user?.fcmtoken !== fcmToken) {
      // Send the token to your backend or use it as needed
      try {
        api.post(
          "/user/addFCMToken",
          {
            fcmToken,
          },
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );
      } catch (error) {
        // alert(error);
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <ModalWindow />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/register/:inviteCode" element={<Register />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verifycode" element={<VerifyCode />} />
          <Route path="/resetpassord" element={<ResetPassword />} />
          <Route
            path="/verifyactivestatuscode"
            element={<VerifyActiveCodeAuth />}
          />
          <Route element={<ProtectedRouteMiddleware />}>
            <Route path="/training" element={<Training />}>
              <Route index element={<TableEmployeesSummary />} />
              <Route
                path="org/:organization_id"
                element={<TableOrganisations />}
              />
              <Route
                path="org/:organization_id/loc/:location_id/"
                element={<TableLocation />}
              />
            </Route>
            <Route path="/training-portal" element={<TrainingPortal />} />
            <Route
              path="/exam-training-portal"
              element={<ExamTrainingPortal />}
            />
            <Route path="/savings" element={<Savings />} />
            <Route path="/settings" element={<Settings />} />
            {/*Dashboard*/}
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="locations" element={<TableLocations />} />
              <Route
                path="organizations/:organizationId/locations/:locationId"
                element={<TableDeviceOneLocation />}
              />
              <Route element={<ProtectedRouteMiddlewareSuperAdminHalcyonEco />}>
                <Route
                  exact
                  path="organizations"
                  element={<TableOrganizationSuperAdmin />}
                />
                <Route
                  exact
                  path="organizations/:organizationId"
                  element={<TableLocations />}
                />
              </Route>
            </Route>
            {/*Dashboard*/}
          </Route>
          <Route element={<ProtectedRouteMiddlewareSuperAdminHalcyonEco />}>
            <Route path="/devices" element={<Devices />} />
            <Route path="/super-admin" element={<SuperAdmin />} />
          </Route>
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
