import React, { useEffect, useState } from "react";
import Drawer from "../../components/drawer";
import ProfileDrawer from "../../components/profile-drawer";
import Sidebar from "../../components/sidebar";
import TableDevices from "../../components/table-devices";
import { useDevicesData } from "../../services/devices/queries";

const Devices = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [location, setLocation] = useState();
  const [filteredDatastate, setFilteredData] = useState([]);
  const token = localStorage.getItem("token");

  const { error: errordevices, data: devicesData } = useDevicesData(
    setFilteredData,
    setLocation
  );

  useEffect(() => {
    if (devicesData) {
      setLocation(devicesData);
      setFilteredData(devicesData);
    }
  }, [token, devicesData]);

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
  };
  const toggleDrawer1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen(false);
  };
  const searchhandler = (event) => {
    const query = event?.target?.value;
    setFilteredData(
      location.filter(
        (item) =>
          item?.id?.toLowerCase().includes(query) ||
          item?.location_name?.toLowerCase().includes(query) ||
          item?.name?.toLowerCase().includes(query)
      )
    );
  };

  return (
    <React.Fragment>
      <div className="w-[100%]">
        <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
        <ProfileDrawer isOpen1={isOpen1} toggleDrawer1={toggleDrawer1} />
        <div className="w-[250px] xl:flex hidden">
          <Sidebar />
        </div>
        <div className=" xl:w-[calc(100%-240px)] w-[100%] xl:ml-[240px] bg-[#f7f7f7] flex justify-between ">
          <div className="w-[100%] sm:p-10 p-4">
            <div
              className={`w-full h-[40px] flex flex-row justify-between items-center py-2 px-2 ${
                screenWidth <= 640 || screenWidth >= 1025 ? "hidden" : "block"
              }`}
            >
              <button onClick={() => toggleDrawer()}>
                <img src="/images/burger-open.svg" alt="burger" />
              </button>
            </div>
            {screenWidth < 640 && (
              <div className="fixed z-30 top-0 left-0 w-full h-[88px] bg-white flex flex-row justify-between items-center py-7 px-5">
                <button onClick={() => toggleDrawer()}>
                  <img src="/images/burger-open.svg" alt="burger" />
                </button>
              </div>
            )}
            <div
              className={`md:flex justify-between items-center ${
                screenWidth < 640 ? "mt-8" : ""
              }`}
            >
              <p className="font-bold lg:text-[32px] text-[20px]"> Devices</p>

              <div className="relative max-w-[400px] w-[100%] z-0 md:mt-0 mt-4">
                <svg
                  className="w-4 h-4 text-[#32383F] absolute top-4 left-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <input
                  type="text"
                  id="default-search"
                  onChange={searchhandler}
                  className=" max-w-[400px] w-[100%] p-4 pl-10 text-sm text-[#808994] rounded-xl bg-white"
                  placeholder="Search Devices"
                />
              </div>
            </div>
            <TableDevices data={filteredDatastate} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Devices;
