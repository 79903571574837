import React, { useEffect, useMemo, useState } from "react";
import SortButton from "../sort-button";
import useSortData from "../../hooks/useSortData";
import { useOrganizationStore } from "../../storesZustand/useOrganizationStore";
import { useLocation, useNavigate } from "react-router-dom";

import DropdownDays from "../dropdown-days";
import SearchBar from "../search-bar";
import StatBlock from "../stat-block/StatBlock";
import { formattedCurrency } from "../../helpers/helpers";
import { useDashboardDataStore } from "../../storesZustand/useDashboardDataStore";
import { listOfDates } from "../../utils/constans/listOfDates";
import { useUserStore } from "../../storesZustand/useUserStore";
import { locale } from "../../utils/constans/locaLanguage";
import { useViewDasboardStore } from "../../storesZustand/useViewDasboardStore";
import { useDashboardData } from "../../services/dashboard/queries";
import BreadcrumbsComponent from "../UI/breadcrumbs";
import Chart from "../UI/chart";
import Map from "../map";
import { ModalTypes } from "../all-modal-window/modal-window";

const TableOrganizationSuperAdmin = () => {
  const [user] = useUserStore((state) => [state.user]);

  const { isLoading } = useDashboardData(user?.role_id, user?.organization_id);

  const [dashboardData, setLocationWithAnalyticsOneOrganization] =
    useDashboardDataStore((state) => [
      state.devicesWithLocationWithAnalytics,
      state.setLocationWithAnalyticsOneOrganization,
    ]);

  const [organizations] = useOrganizationStore((state) => [
    state.organizations,
  ]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState(listOfDates[0].name);
  const [searchQuery, setSearchQuery] = useState("");

  const [isOpen, isxOpen1, setIsOpen, setIsOpen1] = useViewDasboardStore(
    (store) => [store.open1, store.open2, store.setIsOpen, store.setIsOpen1]
  );
  const [setDisplayModalWindowType, setCurrentOpenIdForEditModalWindow] =
    useViewDasboardStore((state) => [
      state.setDisplayModalWindowType,
      state.setCurrentOpenIdForEditModalWindow,
    ]);

  const navigate = useNavigate();
  const location = useLocation();

  const { sortedData, sortData } = useSortData(organizations);

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);

  const handlerClickButton = (item) => {
    const organizationId = item.id;
    setLocationWithAnalyticsOneOrganization(null);
    navigate(`/dashboard/organizations/${organizationId}`);
  };

  const handlerCickButtonForOpenEditModal = (id, typeModalWindow) => {
    setDisplayModalWindowType(typeModalWindow);
    setCurrentOpenIdForEditModalWindow(id);
  };

  const handlerCickButtonDawnloadData = (id, typeModalWindow) => {
    setDisplayModalWindowType(typeModalWindow);
    setCurrentOpenIdForEditModalWindow(id);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sorted data based on the search query
  const filteredData = sortedData?.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="w-[100%] h-full sm:p-10 p-4 pt-[100px] sm:pr-[120px] sm:pt-[25px]">
      <div
        className={`w-full h-[40px] flex flex-row justify-between items-center py-2 px-2 ${
          screenWidth <= 640 || screenWidth >= 1025 ? "hidden" : "block"
        }`}
      >
        <button onClick={() => toggleDrawer()}>
          <img src="/images/burger-open.svg" alt="burger" />
        </button>
      </div>
      {screenWidth < 640 && (
        <div className="fixed z-30 top-0 left-0 w-full h-[88px] bg-white flex flex-row justify-between items-center py-7 px-5">
          <button onClick={() => toggleDrawer()}>
            <img src="/images/burger-open.svg" alt="burger" />
          </button>
          {/* <DropdownDays
            screenWidth={screenWidth}
            data={listOfDates}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            // optionDefault={listOfDates[0].name}
          /> */}
        </div>
      )}
      <div className="flex justify-between mb-4 items-center">
        <div className="flex items-start gap-2">
          {user && (
            <BreadcrumbsComponent
              breadCrumbsArr={[
                { name: "Overvew", url: "" },
                { name: user.organization_name, url: location.pathname },
              ]}
            />
          )}
        </div>
        <div className="flex items-start gap-2">
          {screenWidth >= 640 && (
            <SearchBar
              placeholder="Type to search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          )}
          {/* {screenWidth >= 640 && (
            <DropdownDays
              screenWidth={screenWidth}
              data={listOfDates}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              // optionDefault={listOfDates[0].name}
            />
          )} */}
        </div>
      </div>
      <div className="flex items-center gap-5 justify-between flex-col sm:flex-row">
        <div className="flex gap-2 justify-between">
          <div className="flex flex-col gap-2">
            <StatBlock
              variant="dark"
              icon="/images/Frame (10).svg"
              description="Total Savings"
              amount={
                dashboardData?.totalLitres
                  ? formattedCurrency(
                      dashboardData?.totalSavings,
                      dashboardData?.currency
                    )
                  : "0"
              }
              unit="€"
            />
            <StatBlock
              variant="light"
              icon="/images/carbon-footprint.svg"
              description="Carbon Saved"
              amount={
                dashboardData?.totalLitres
                  ? parseInt(dashboardData.totalLitres * 3.81).toLocaleString(
                      locale
                    )
                  : "0"
              }
              unit="kg CO2e"
            />
          </div>
          <div className="flex flex-col gap-2">
            <StatBlock
              variant="light"
              icon="/images/Frame (12).svg"
              description="Savings last 30 Days"
              amount={
                dashboardData?.totalLitresLast30Days
                  ? formattedCurrency(
                      dashboardData?.savingsLast30Days,
                      dashboardData?.currency
                    )
                  : "0"
              }
              unit="€"
            />
            <StatBlock
              variant="light"
              icon="/images/Frame (12).svg"
              description="Litres of oil saved"
              amount={
                dashboardData?.totalLitres
                  ? parseInt(dashboardData?.totalLitres).toLocaleString(
                      locale
                    ) + " L"
                  : "0"
              }
              unit="L"
            />
          </div>
        </div>
        <div className="flex justify-center w-full">
          <Chart
            variant="light"
            data={
              dashboardData?.last7daysData ? dashboardData?.last7daysData : ""
            }
            screenWidth={screenWidth}
          />
        </div>
      </div>
      <div className="flex gap-8 flex-col mt-8 sm:flex-row">
        {screenWidth < 640 && (
          <SearchBar
            placeholder="Type to search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        )}
      </div>
      <div className="mt-1 w-100">
        <div className="table-container overflow-auto w-full">
          <table className="divide-y-8 divide-[#F4F4F4] w-full">
            <thead>
              <tr>
                <th className="whitespace-nowrap flex items-center gap-2 px-6 py-2 text-left md:text-base text-sm font-normal text-[#B0CAD1] tracking-wider">
                  <p>Location Name</p>
                  <SortButton
                    handlerToggler={sortData}
                    text="A - Z"
                    sortBy="location_name"
                  />
                </th>
                <th className="px-6 py-2 text-start md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
                  Address
                </th>
                <th className="px-6 py-2 text-start md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
                  Contact Name
                </th>
                <th className="px-6 py-2 text-center md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
                  Reports
                </th>
              </tr>
            </thead>
            <tbody className="divide-y-8 divide-[#F4F4F4]">
              {filteredData?.map((item, index) => (
                <tr
                  className="bg-white rounded-lg"
                  key={item?.id}
                  onClick={() => handlerClickButton(item)}
                >
                  <td className="px-6 py-3 whitespace-nowrap rounded-l-full">
                    <div className="flex gap-3">
                      <img
                        className="cursor-pointer"
                        onClick={() =>
                          handlerCickButtonForOpenEditModal(
                            item.id,
                            ModalTypes.EDIT_ORGANIZATION
                          )
                        }
                        src="/images/pencil.svg"
                        alt=""
                      />
                      <div className="text-base font-medium text-[#1E3A56]">
                        {item?.name ? item?.name : ""}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex gap-3">
                      <div className="text-base text-[#1E3A56]">
                        {item?.address1 ? item.address1 : ""}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex gap-3">
                      <div className="text-base text-[#1E3A56]">
                        {item?.billing_contact_name
                          ? item?.billing_contact_name
                          : ""}
                      </div>
                    </div>
                  </td>
                  <td className="lg:px-6 px-20 py-3 whitespace-nowrap rounded-r-full">
                    <div className="text-base text-[#1E3A56] text-center flex gap-2 float-right">
                      <button
                        onClick={() => handlerClickButton(item)}
                        className="w-[80px] h-[30px]  flex justify-center items-center font-ABeeZee  text-xs font-normal text-[#8597A8] border border-[#8597A8] rounded-[7px] py-3 px-6"
                      >
                        View
                      </button>
                      <button
                        onClick={() =>
                          handlerCickButtonDawnloadData(
                            item.id,
                            ModalTypes.DOWNLOAD_ORG_DATA
                          )
                        }
                        className="w-[80px] h-[30px] flex justify-center items-center font-ABeeZee text-xs font-normal text-white bg-[#8597A8] border border-[#8597A8] rounded-[7px] py-3 px-6"
                      >
                        Download
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          {dashboardData?.locationsWithAnalyticsByDays &&
          dashboardData?.locationsWithAnalyticsByDays.length > 0 ? (
            <Map devices={dashboardData.locationsWithAnalyticsByDays} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TableOrganizationSuperAdmin;
