import React, { useMemo } from "react";

function StatBlock({ unit, icon, description, amount, variant }) {
  const variantClass = useMemo(() => {
    return variant === "dark" ? "training-box2" : "training-box3";
  }, [variant]);

  return (
    <div className={`${variantClass} bg-white relative flex flex-col flex-1`}>
      <div
        className={`absolute top-6 right-6 text-${
          variant === "dark" ? "white" : "#32383F"
        } text-2xl opacity-80`}
      >
        ℮
      </div>
      <div
        className={`${
          variant === "dark" ? "bg-[white]" : "bg-gray-200"
        } rounded-full h-[44px] w-[44px] flex justify-center items-center`}
      >
        <img src={icon} alt="" />
      </div>
      <p
        className={`text-[${
          variant === "dark" ? "#C6C6C6" : "#787878"
        }] mt-2 text-sm`}
      >
        {description}
      </p>
      <div className="flex flex-row items-end mt-auto">
        <p
          className={`font-bold ${
            variant === "dark" ? "text-white" : "text-[#32383F]"
          } text-2xl`}
        >
          {amount}
        </p>
        {unit === "kg CO2e" ? (
          <span className="ml-2 text-sm">kg CO2e</span>
        ) : null}
      </div>
    </div>
  );
}

export default StatBlock;
