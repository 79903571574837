import { useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useUserStore } from "../../storesZustand/useUserStore";

// use redux for protection insted of localstorage

export function isAuthenticated() {
  // const token = localStorage.getItem("token");

  // if (!token) {
  //   return false;
  // }
  // // Check if the token is expired
  // const decodedToken = jwt_decode(token);
  // const currentTime = Date.now() / 1000;
  return true;
}

export const ProtectedRouteMiddleware = () => {
  const [user, setUsersDataAfterLogin] = useUserStore((state) => [
    state.user,
    state.setUsersDataAfterLogin,
  ]);
  const role = user?.role;
  const location = useLocation();
  if (!isAuthenticated()) {
    localStorage.clear();
    return <Navigate to="/" replace />;
  }
  // Redirect to verify-user if the user is not active
  if (user && user?.active === false) {
    return <Navigate to="/verifycode" state={{ email: user.email }} replace />;
  }

  const protectedPaths = [
    "/dashboard/organizations",
    "organizations/:organizationId",
    "/dashboard/locations",
    "/organizations/:organizationId/locations/:locationId",
    "/training",
    "/savings",
    "/settings",
  ];

  if (
    role === "employee" &&
    protectedPaths.some((path) => window.location.pathname === path)
  ) {
    return <Navigate to="/" replace />;
  }
  if (role === "admin" && location.pathname === "/training") {
    return <Navigate to={`/training/org/${user.organization_id}`} replace />;
  }

  return <Outlet />;
};

// export const ProtectedRouteMiddlewareSuperAdmin = () => {
//   const token = localStorage.getItem('token');
//   const [user] = useUserStore((state) => [state.user]);
//   const role = user?.role;
//   const location = useLocation();
//
//   if (!token || !isAuthenticated()) {
//     localStorage.clear();
//     return <Navigate to='/' replace />;
//   }
//
//   if (role === 'admin' && location.pathname === '/training') {
//     return <Navigate to={`/training/org/${user.organizationId}`} replace />;
//   }
//
//   if (role === 'admin') {
//     return <Navigate to='/training' replace />;
//   }
//
//   return <Outlet />;
// };

export const ProtectedRouteMiddlewareSuperAdminHalcyonEco = () => {
  const token = localStorage.getItem("token");
  const [user] = useUserStore((state) => [state.user]);
  const role = user?.role;
  const location = useLocation();

  if (!token || !isAuthenticated()) {
    localStorage.clear();
    return <Navigate to="/" replace />;
  }

  if (role === "superadmin" && user.organization_id !== 1)
    return <Navigate to="/dashboard/locations" replace />;

  if (role === "employee") return <Navigate to="/training-portal" replace />;

  if (role === "admin")
    return <Navigate to={`/training/org/${user.organizationId}`} replace />;

  return <Outlet />;
};

export const PublicRouteMiddleware = () => {
  const token = localStorage.getItem("token");
  const [user] = useUserStore((state) => [state.user]);

  useEffect(() => {
    if (token) {
      // Create a new script element
      const script = document.createElement("script");
      script.src = "//code.tidio.co/z9magf4whubw9ruamgzzb7djulsekkij.js";
      script.async = true;
      // Find the body element and insert the script just before the </body> tag
      document.body.appendChild(script);
    }
  }, [token]);

  if (!token || !isAuthenticated()) {
    localStorage.clear();
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
