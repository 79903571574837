import axios from "axios";

// Determine the base URL based on the environment
export const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.halcyon-eco.com"
    : "http://localhost:3900";

// Create an axios instance
const api = axios.create({ baseURL });

// Flag and promise for refresh concurrency
let isRefreshing = false;
let refreshTokenPromise = null;

/**
 * Helper to refresh tokens
 */
async function refreshTokens(refreshToken) {
  try {
    const response = await api.post("/user/refresh", { refreshToken });
    if (response.status === 200) {
      const { idToken } = response.data.tokens || "";
      if (idToken) {
        localStorage.setItem("token", idToken);
        return idToken; // Return the new token
      }
    }
  } catch (error) {
    console.error("Failed to refresh tokens:", error);
    // Clear tokens and redirect on refresh failure
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
    throw new Error("Refresh failed - redirecting to login");
  }
  return null;
}

// Request interceptor to attach token if it exists
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    return config;
  },
  (error) => {
    // Reject any request error
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiry
api.interceptors.response.use(
  (response) => response, // Pass through successful responses
  async (error) => {
    const originalRequest = error.config;

    // If the failing request is the refresh call itself, do not retry
    if (
      error.config &&
      error.config.url &&
      error.config.url.endsWith("/user/refresh")
    ) {
      return Promise.reject(error);
    }

    // Check if token expired and we haven't retried yet
    if (
      error.response &&
      error.response.data &&
      error.response.data.name === "TokenExpiredError" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true; // Mark this request as having been retried

      // Only refresh once at a time
      if (!isRefreshing) {
        isRefreshing = true;
        refreshTokenPromise = (async () => {
          const refreshToken = localStorage.getItem("refreshToken");
          const newToken = await refreshTokens(refreshToken);
          isRefreshing = false;
          return newToken;
        })();
      }

      try {
        const newToken = await refreshTokenPromise;
        if (newToken) {
          // Update the token for the original request and retry it
          originalRequest.headers["x-auth-token"] = newToken;
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.error("Failed to refresh token:", refreshError);
        return Promise.reject(refreshError);
      }
    }

    // If it's not a token expiry error or retries failed, just reject
    return Promise.reject(error);
  }
);

export default api;
