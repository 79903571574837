import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { useUserStore } from "../../storesZustand/useUserStore";
import axios from "axios";
import { baseURL } from "../../utils/api";

const VerifyCodeAuth = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [resendSent, setResendSent] = useState(false);
  const location = useLocation();
  const token = localStorage.getItem("token");
  const { email, password } = location.state || {};
  const [user, setUser] = useUserStore((state) => [
    state.user,
    state.setUsersDataAfterLogin,
  ]);

  console.log({ email, password });

  // Read the existing data from the JSON file

  // Add the new user data to the array

  // Write the updated array back to the JSON file

  const handleSignUp = () => {
    navigate("/");
  };
  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  };
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .min(5, "Sign Up Code must be at least 6 characters")
      .required("Sign Up Code is required"),
    // email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const handleSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);
    const { code } = values;
    setSubmitting(false);
    api
      .post(
        "/user/verified",
        {
          email: user?.email || location?.state?.email,
          verification: code,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("verified");
          if (email && password) {
            api
              .post("/user/login", {
                email,
                password,
              })
              .then((res) => {
                if (res.status === 200) {
                  toast.success(res?.data?.message);
                  localStorage.setItem("token", res?.data?.tokens?.idToken);
                  localStorage.setItem(
                    "refreshToken",
                    res?.data?.tokens?.refreshToken
                  );
                  setUser(res?.data?.data);

                  if (res?.data?.data?.role === "employee") {
                    navigate("/training-portal");
                    return;
                  }
                  navigate("/");

                  setIsLoading(false);
                }
              });
          } else {
            navigate("/");
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        setSubmitting(false);
        toast.error(err?.response?.data?.error);
      });
  };
  const formik = useFormik({
    initialValues: {
      code: "",
      email: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleResend = async () => {
    if (resendSent) return;

    axios
      .post(`${baseURL}/user/resendCode`, {
        email: user?.email,
      })
      .then((data) => {
        if (data) setResendSent(true);
        setTimeout(() => {
          setResendSent(false);
        }, 5000);
      });
    navigate("/verifycode", {
      state: {
        email: user?.email,
      },
    });
  };

  return (
    <React.Fragment>
      <div className="bg-[#040824] w-full h-screen overflow-scroll my-scroller text-white">
        <div className="max-w-[400px] w-[100%] m-auto pt-[50px] px-[10px]">
          <img src="/images/Rectangle.png" alt="" />
          <p className="text-[45px] font-bold mt-3">Verify Code</p>

          <form onSubmit={formik.handleSubmit}>
            <div className="form mt-5 ">
              <div className="relative mt-4">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-7 h-7 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                    />
                  </svg>
                </span>
                <input
                  className="pl-12 w-full pr-3 py-2 bg-[#2A2E45] h-[56px] rounded border border-gray-300  focus:outline-none"
                  type="text"
                  placeholder="Code"
                  autoComplete="off"
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.signUpCode && formik.errors.code && (
                <div className="text-red-500 mt-1 text-[12px]">
                  {formik.errors.code}
                </div>
              )}
            </div>
            <p className="text-left text-[#E4A62D] text-[18px] mt-3">
              {user?.email || ""}
            </p>
            <div>
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="register-button"
              >
                {isLoading ? (
                  <div role="status" className="mx-auto w-[30px]">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#E4A61A]"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  <span> Verify Email</span>
                )}
              </button>

              <p
                className="text-[18px] mt-3 underline text-right cursor-pointer"
                onClick={handleResend}
              >
                Resend Code
              </p>
              {resendSent ? (
                <p className="text-[18px] text-[#E4A62D] mt-3 text-center cursor-pointer">
                  Code Has Been Sent to your Email Address
                </p>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VerifyCodeAuth;
