import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ModalSelect from "../../UI/modal-select";
import { useOrganizationStore } from "../../../storesZustand/useOrganizationStore";
import { useLocationStore } from "../../../storesZustand/useLocationStore";
import { useInviteUserMutation } from "../../../services/invite/mutation";
import ModalSelectWithCheckBox from "../../UI/modal-selectWithChackBox";
import Spinner from "../../UI/Spinner";
import { rolesUser } from "../../../utils/constans/roleObject";

const ModalUser = ({ handleClose, currentAuthUser }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [filteredLocation, setFilteredLocation] = useState([]);
  //Zustand state
  const [locations] = useLocationStore((state) => [state.locations]);
  const [organizations] = useOrganizationStore((state) => [
    state.organizations,
  ]);

  //react-query
  const { mutate, isLoading } = useInviteUserMutation();
  let filteredOrganization = organizations;

  const initialValues = {
    role: "",
    organization: "",
    location: [],
    email: "",
  };

  const validationSchema = Yup.object().shape({
    role: Yup.string().required("Role is required"),
    organization: Yup.string().required("Organization is required"),
    email: Yup.string()
      .test("is-valid-email-list", "Invalid email format", (value) => {
        if (!value) return false;
        const emails = value.split(",").map((email) => email.trim());
        // Regular expression to validate each email
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emails.every((email) => emailRegex.test(email));
      })
      .required("Email is required"),
    location: Yup.array().test(
      "location-required-based-on-role",
      "At least one location is required",
      function (value) {
        const { role } = this.parent; // Access other fields in the form
        if (role !== "3") {
          return value && value.length > 0; // Ensure location is not empty
        }
        return true; // Pass validation if role is 3
      }
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setErrorMessage("");
      mutate(
        {
          role_id: values.role,
          email: values.email.includes(",")
            ? values.email.split(",").map((email) => email.trim())
            : [values.email],
          organization_id: values.organization,
          location_ids: values.location,
        },
        {
          onSuccess: ({ data }) => {
            let output = data?.data;
            if (output?.failedEmails?.length > 0) {
              setErrorMessage(
                `${output?.successInvites} email${
                  output?.successInvites > 1 ? "s" : ""
                } sent, the above email${
                  output?.failedEmails?.length > 1 ? "s" : ""
                } failed due to already being invited or otherwise.`
              );
              const emailString =
                output?.failedEmails > 1
                  ? output?.failedEmails.join(", ")
                  : output?.failedEmails[0] || "";

              // Set the value in Formik only if necessary
              formik.setFieldValue("email", emailString);
            } else {
              handleClose();
            }
          },
          onError: (error) => {
            console.log({ error });
          },
        }
      );
    },
  });

  const handleCloseFunc = React.useCallback(() => {
    formik.resetForm();
    handleClose();
  }, [formik, handleClose]);

  useEffect(() => {
    const filtered = locations.filter(
      (location) => location.organization_id === formik.values.organization
    );
    setFilteredLocation(filtered);
  }, [formik.values.organization, formik.values.location]);

  const rolesUsers = useMemo(() => {
    if (currentAuthUser?.role === "admin") {
      return rolesUser.filter((role) => role.id !== 3);
    } else if (currentAuthUser?.role === "superadmin") {
      return rolesUser;
    }
  }, [currentAuthUser]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col sm:flex-row relative min-h-screen sm:min-h-full">
        <div className="flex-1 px-5 pt-5 pb-8 sm:p-10 flex flex-col gap-[25px]">
          <div className="  self-end mb-[-36px]">
            <button onClick={handleCloseFunc}>
              <img src="/images/close.svg" alt="close" />
            </button>
          </div>
          <div className="flex gap-4 items-center">
            <div className="w-[40px] h-[40px]">
              <img
                className=" w-full h-full"
                src="/images/addUserIcon.svg"
                alt="location"
                style={{ fill: "#10223F" }}
              />
            </div>
            <h2 className="text-[24px] font-bold text-[#32383F]">
              Invite User
            </h2>
          </div>
          <div className="flex flex-col relative">
            <label className="text-[15px] font-bold">
              Please select your role
              <span className="text-[#F91212]">*</span>
            </label>
            <div className="w-full mt-5">
              <ModalSelect
                data={rolesUsers}
                optionDefault="Role"
                formik={formik}
                field="role"
                // dropListUp={true}
              />
            </div>
            {formik.touched.role &&
              formik.touched.role &&
              formik.errors.role && (
                <div className="absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]">
                  {formik.errors.role}
                </div>
              )}
          </div>
          <div className="flex flex-col relative">
            <label className="text-[15px] font-bold">
              Organisation
              <span className="text-[#F91212]">*</span>
            </label>
            <div className="w-full mt-5">
              <ModalSelect
                data={filteredOrganization}
                optionDefault="Organization"
                formik={formik}
                field="organization"
                // dropListUp={true}
              />
            </div>
            {formik.touched.organization && formik.errors.organization && (
              <div className="absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]">
                {formik.errors.organization}
              </div>
            )}
          </div>
          <div className="flex flex-col relative mb-1">
            <label className="text-[15px] font-bold">
              Location
              {formik.values.role !== 3 && formik.values.role !== null ? (
                <span className="text-[#F91212]"> *</span>
              ) : (
                <span className="text-[#787878]"> (optional)</span>
              )}
            </label>
            <div className="w-full mt-5">
              <ModalSelectWithCheckBox
                data={filteredLocation}
                optionDefault="Location"
                formik={formik}
                field="location"
                // dropListUp={true}
              />
            </div>
            {formik.touched.location && formik.errors.location && (
              <div className="absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]">
                {formik.errors.location}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 relative">
            <label className="text-[15px] ml-0.5 font-bold">
              Email
              <span className="text-[#F91212]">*</span>
            </label>
            <input
              className="w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]"
              type="text"
              name="email"
              placeholder="Email Account"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]">
                {formik.errors.email}
              </div>
            )}
          </div>
          {errorMessage ? (
            <p className="text-[#376dba] mt-2">{errorMessage}</p>
          ) : null}
          <div className="flex gap-[9px] self-end">
            <button
              onClick={handleCloseFunc}
              className="text-[11px] rounded-[7px] border-[1px] border-[#E4A50E] text-[#E4A50E] px-4 py-1"
            >
              Go Back
            </button>
            <button
              className="text-[11px] rounded-[7px] min-w-[72px] border-[1px] border-[#E4A50E] bg-[#E4A50E] text-white px-4 py-1"
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  size={"w-6 h-4"}
                  color={"text-white-200 dark:text-white-800"}
                />
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ModalUser;
