import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import ModalOrganization from "../modal-organization";
import ModalLocation from "../modal-location";
import ModalUser from "../modal-inviteUser";
import ModalDevice from "../modal-device";
import { useUserStore } from "../../../storesZustand/useUserStore";
import { useViewDasboardStore } from "../../../storesZustand/useViewDasboardStore";
import { useViewEmployeesStore } from "../../../storesZustand/useViewEmployeesStore";
import ModalEmployeesPage from "../modal-employees-page";
import ModalConfirmation from "../modal-confirmation";
import ModalConfirmationToClose from "../modal-сonfirmationToClose";

const usePortal = () => {
  const [container] = React.useState(() => {
    const element = document.createElement("div");
    element.classList.add("portal-container");
    document.body.appendChild(element);
    return element;
  });

  return container;
};

export const ModalTypes = {
  ADD_ORGANIZATION: "Add Organization",
  ADD_LOCATION: "Add Location",
  ADD_DEVICE: "Add Device",
  INVITE_USER: "Invite User",
  EDIT_ORGANIZATION: "Edit Organization",
  EDIT_LOCATION: "Edit Location",
  EDIT_DEVICE: "Edit Device",
  DOWNLOAD_ORG_DATA: "Download data organization",
  DOWNLOAD_LOCATION_DATA: "Download data location",
  DOWNLOAD_DEVICE_DATA: "Download data device",
  SEND_REMINDER: "Send Reminder",
  REMOVE_USER: "Remove User",
  PERMANENTLY_DELETE_USER: "Permanently Delete User",
};

const ModalWindow = ({ screenWidth }) => {
  const [isopenConfirmationModal, setOpenConfirmationModal] = useState(null);

  const [user] = useUserStore((state) => [state.user]);

  const [
    currentClickSendReminderOrDeleteItem,
    setCurrentClickSendReminderOrDeleteItem,
  ] = useViewEmployeesStore((state) => [
    state.currentClickSendReminderOrDeleteItem,
    state.setCurrentClickSendReminderOrDeleteItem,
  ]);

  const [
    openModalType,
    setDisplayModalWindowType,
    currentOpenIdForEditModalWindow,
    setCurrentOpenIdForEditModalWindow,
    currentDawnloadExcelOrganizationIdForDevice,
    setCurrentDawnloadExcelOrganizationIdForDevice,
  ] = useViewDasboardStore((state) => [
    state.displayModalWindowType,
    state.setDisplayModalWindowType,
    state.currentOpenIdForEditModalWindow,
    state.setCurrentOpenIdForEditModalWindow,
    state.currentDawnloadExcelOrganizationIdForDevice,
    state.setCurrentDawnloadExcelOrganizationIdForDevice,
  ]);

  const closeModal = () => {
    setDisplayModalWindowType("");
    setCurrentOpenIdForEditModalWindow(null);
    setCurrentClickSendReminderOrDeleteItem(null);
    setCurrentDawnloadExcelOrganizationIdForDevice(null);
  };

  useEffect(() => {
    if (openModalType) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [openModalType]);

  const portalContainer = usePortal();

  const handleCloseOnOverlay = (e) => {
    if (
      e.target === e.currentTarget &&
      (openModalType === ModalTypes.SEND_REMINDER ||
        openModalType === ModalTypes.DOWNLOAD_DEVICE_DATA ||
        openModalType === ModalTypes.DOWNLOAD_LOCATION_DATA ||
        openModalType === ModalTypes.DOWNLOAD_ORG_DATA ||
        openModalType === ModalTypes.REMOVE_USER)
    ) {
      closeModal();
    }

    if (
      e.target === e.currentTarget &&
      (openModalType === ModalTypes.ADD_DEVICE ||
        openModalType === ModalTypes.EDIT_DEVICE ||
        openModalType === ModalTypes.ADD_LOCATION ||
        openModalType === ModalTypes.EDIT_LOCATION ||
        openModalType === ModalTypes.ADD_ORGANIZATION ||
        openModalType === ModalTypes.EDIT_ORGANIZATION ||
        openModalType === ModalTypes.INVITE_USER)
    ) {
      setOpenConfirmationModal(true);
    }
  };

  const modalStyleOneColumn =
    "flex flex-col sm:justify-center absolute sm:fixed rounded-xl max-w-[380px] sm:max-w-[690px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full sm:max-w-[1286px] sm:max-h-[764px] sm:rounded-[15px] bg-white";
  const modalStyleTwoColumns =
    "flex flex-col sm:justify-center absolute sm:fixed top-0 sm:top-[50%] left-0 sm:left-[50%] sm:translate-x-[-50%] sm:translate-y-[-50%] w-full sm:max-w-[1286px] sm:max-h-[764px] sm:rounded-[15px] bg-white";

  return openModalType
    ? createPortal(
        <>
          <div
            onMouseDown={handleCloseOnOverlay}
            className="fixed overflow-scroll sm:overflow-hidden bottom-0 sm:fixed top-0 left-0 w-full h-full bg-[#00000080] flex items-center justify-center z-[101]"
          >
            <div
              className={
                openModalType === ModalTypes.INVITE_USER ||
                openModalType === ModalTypes.ADD_DEVICE ||
                openModalType === ModalTypes.EDIT_DEVICE ||
                openModalType === ModalTypes.DOWNLOAD_ORG_DATA ||
                openModalType === ModalTypes.DOWNLOAD_LOCATION_DATA ||
                openModalType === ModalTypes.DOWNLOAD_DEVICE_DATA ||
                openModalType === ModalTypes.SEND_REMINDER ||
                openModalType === ModalTypes.REMOVE_USER ||
                openModalType === ModalTypes.PERMANENTLY_DELETE_USER
                  ? modalStyleOneColumn
                  : modalStyleTwoColumns
              }
              style={{
                boxShadow: "0 20px 100px rgba(85, 85, 85, 0.5)",
                zIndex: "1000",
              }}
            >
              {openModalType === ModalTypes.ADD_ORGANIZATION && (
                <ModalOrganization
                  currentAuthUser={user}
                  handleClose={closeModal}
                  type={openModalType}
                />
              )}
              {openModalType === ModalTypes.ADD_LOCATION && (
                <ModalLocation
                  currentAuthUser={user}
                  handleClose={closeModal}
                  type={openModalType}
                />
              )}
              {openModalType === ModalTypes.ADD_DEVICE && (
                <ModalDevice
                  currentAuthUser={user}
                  handleClose={closeModal}
                  type={openModalType}
                />
              )}
              {openModalType === ModalTypes.INVITE_USER && (
                <ModalUser
                  currentAuthUser={user}
                  handleClose={closeModal}
                  type={openModalType}
                />
              )}
              {openModalType === ModalTypes.EDIT_ORGANIZATION && (
                <ModalOrganization
                  currentAuthUser={user}
                  handleClose={closeModal}
                  type={openModalType}
                  currentIdForGetData={currentOpenIdForEditModalWindow}
                />
              )}
              {openModalType === ModalTypes.EDIT_LOCATION && (
                <ModalLocation
                  currentAuthUser={user}
                  handleClose={closeModal}
                  type={openModalType}
                  currentIdForGetData={currentOpenIdForEditModalWindow}
                />
              )}
              {openModalType === ModalTypes.EDIT_DEVICE && (
                <ModalDevice
                  currentAuthUser={user}
                  handleClose={closeModal}
                  type={openModalType}
                  currentIdForGetData={currentOpenIdForEditModalWindow}
                />
              )}
              {openModalType === ModalTypes.DOWNLOAD_ORG_DATA && (
                <ModalConfirmation
                  handleClose={closeModal}
                  type={openModalType}
                  currentIdForGetData={currentOpenIdForEditModalWindow}
                />
              )}
              {openModalType === ModalTypes.DOWNLOAD_LOCATION_DATA && (
                <ModalConfirmation
                  handleClose={closeModal}
                  type={openModalType}
                  currentIdForGetData={currentOpenIdForEditModalWindow}
                />
              )}
              {openModalType === ModalTypes.DOWNLOAD_DEVICE_DATA && (
                <ModalConfirmation
                  handleClose={closeModal}
                  type={openModalType}
                  currentIdForGetData={currentOpenIdForEditModalWindow}
                  organizationId={currentDawnloadExcelOrganizationIdForDevice}
                />
              )}
              {openModalType === ModalTypes.SEND_REMINDER && (
                <ModalEmployeesPage
                  handleClose={closeModal}
                  type={openModalType}
                  currentIdForGetData={currentClickSendReminderOrDeleteItem}
                />
              )}
              {openModalType === ModalTypes.REMOVE_USER && (
                <ModalEmployeesPage
                  handleClose={closeModal}
                  type={openModalType}
                  currentIdForGetData={currentClickSendReminderOrDeleteItem}
                />
              )}
              {openModalType === ModalTypes.PERMANENTLY_DELETE_USER && (
                <ModalEmployeesPage
                  handleClose={closeModal}
                  type={openModalType}
                  currentIdForGetData={currentClickSendReminderOrDeleteItem}
                />
              )}
            </div>
          </div>
          {isopenConfirmationModal && (
            <div className="fixed overflow-scroll sm:overflow-hidden bottom-0 sm:fixed top-0 left-0 w-full h-full bg-[#00000030] flex items-center justify-center z-[101]">
              <div
                className="flex flex-col sm:justify-center absolute sm:fixed rounded-xl md:max-w-[520px] sm:max-w-[590px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full sm:max-h-[764px] sm:rounded-[15px] bg-white z-1001"
                style={{
                  boxShadow: "0 20px 100px rgba(85, 85, 85, 0.5)",
                  zIndex: "1001",
                }}
              >
                <ModalConfirmationToClose
                  type={openModalType}
                  handlerClosePerentWindow={closeModal}
                  handlerClose={setOpenConfirmationModal}
                />
              </div>
            </div>
          )}
        </>,
        portalContainer
      )
    : null;
};

export default ModalWindow;
